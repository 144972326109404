import { useModal } from "../../context/Modal";
import { useLoaderData } from "@remix-run/react";
import { CartIcon } from "~/assets/icons/Cart";
import type { Cart } from "../../../../types/ShoppingCart";

export const ShoppingCart = ({ fill = "black" }: { fill?: string }) => {
  const { setShoppingCartVisible } = useModal();
  const { cart } = useLoaderData<{
    cart: Cart;
  }>();

  const count =
    cart && cart.items
      ? cart.items.map((i) => i.quantity).reduce((a, b) => a + b, 0)
      : 0;
  return (
    <div
      className="flex cursor-pointer flex-row items-center gap-[1px]"
      onClick={() => setShoppingCartVisible("shoppingCart")}
    >
      <div
        className={`hidden text-xl font-bold uppercase leading-[29px] md:block text-[${fill}]`}
      >
        Karfa
      </div>
      <div className="block md:hidden">
        <CartIcon fill={fill} />
      </div>
      {count > 0 && (
        <div
          className={`flex h-[15px] w-[15px] flex-row items-center justify-center rounded-full border ${fill === "black" ? "border-black text-black" : `border-[${fill}] text-[${fill}]`} text-[10px] font-normal leading-[29px]`}
        >
          {count}
        </div>
      )}
    </div>
  );
};
