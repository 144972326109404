import { useNavigate, useSearchParams } from "@remix-run/react";
import { useEffect, useRef, useState } from "react";
import { useModal } from "../context/Modal";

export default function Search() {
  const { searchVisible, setSearchVisible } = useModal();
  const [top, setTop] = useState("top-[-300px]");
  const navigate = useNavigate();
  const ref = useRef<HTMLInputElement>(null);
  const [searchParams] = useSearchParams();
  const query = searchParams.get("query") || "";

  useEffect(() => {
    if (searchVisible) {
      setTop("top-0");
      ref.current?.focus();
    } else {
      setTop("top-[-300px]");
    }
  }, [searchVisible]);

  return (
    <div
      className={`${top} fixed z-10 h-[300px] w-full bg-white px-4 transition-all duration-300 xs:px-10`}
    >
      <div className="mb-10 flex h-full flex-col justify-end pb-10 align-bottom">
        <input
          key={query}
          ref={ref}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              navigate(`/shop?query=${e.currentTarget.value}`);
              setSearchVisible(false);
            }
          }}
          defaultValue={query}
          placeholder="Sláðu inn leitarorð"
          className=" h-10 rounded-lg bg-transparent px-4 text-2xl font-bold uppercase text-black placeholder-black focus:bg-white focus:placeholder-gray-500 focus:outline-none"
        ></input>
        <div className="w-full border-b-[1px]"></div>
      </div>
    </div>
  );
}
