import { Link } from "@remix-run/react";
import { LinkArrow } from "~/assets/icons/LinkArrow";

export const FooterLink = ({
  children,
  to,
}: {
  children: React.ReactNode;
  to: string;
}) => {
  return (
    <Link
      to={to}
      className="text-black opacity-50 hover:opacity-100 flex flex-row items-center group"
    >
      <div className="mr-2 group-hover:visible invisible">
        <LinkArrow fill="black" />
      </div>
      {children}
    </Link>
  );
};
