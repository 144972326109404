import { FooterLogo } from "~/assets/icons/FooterLogo";
import { Input } from "./Footer/Input";
import { Button } from "./Footer/Button";
import { FooterLink } from "./Footer/Link";
import { FooterLogoLarge } from "~/assets/icons/FooterLogoLarge";
import { Link, useFetcher } from "@remix-run/react";
import { INTENTS } from "~/utils/intents";

export const Footer = () => {
  const fetcher = useFetcher<{ success: boolean }>();

  const disabled =
    fetcher.data?.success || fetcher.state === "submitting" || false;
  return (
    <div className="absolute left-0 right-0  bg-[radial-gradient(120vh_circle_at_50%_190vh,_var(--tw-gradient-stops))] from-[#FF3636]  from-50% via-[#FE74BE] via-60% to-[#E3DACC] to-85% transition-colors duration-1000 md:bg-[radial-gradient(200vh_circle_at_50%_190vh,_var(--tw-gradient-stops))]">
      <div className="mt-20 pt-20 md:grid md:grid-cols-[60%_40%]">
        <div className="flex flex-col md:grid md:grid-rows-2">
          <div className="flex w-full flex-row items-center justify-center border-b-1 border-t-1 border-black py-10 md:justify-start md:border-r-1 md:py-0 md:pl-[172px]">
            <FooterLogo />
          </div>
          <div className="grid grid-cols-2 border-b-1 border-black p-6 text-xl font-normal md:border-t-1">
            <div>Sími</div>
            <div className="opacity-50">537-1877</div>
            <div>Tölvupóstur</div>
            <div className="opacity-50">nola@nola.is</div>
            <div>Heimilisfang</div>
            <div className="opacity-50">Ármúli 38</div>
          </div>
          <div className="col-span-2 flex flex-col border-black py-6 pl-6 text-base md:mb-10 md:border-b-1 md:border-l-1 md:border-r-1 md:pl-[172px]">
            <div className="mb-6">Komdu í Nola klúbbinn</div>

            <fetcher.Form
              method="post"
              className="flex flex-col items-start md:flex-row md:items-center md:justify-start"
            >
              <input type="hidden" name="intent" value={INTENTS.EMAIL_SIGNUP} />
              <Input placeholder="Nafn" name="name" disabled={disabled} />
              <div className="w-3" />
              <Input placeholder="Netfang" name="email" disabled={disabled} />
              <div className="w-6" />
              <Button disabled={disabled}>
                {fetcher.data?.success
                  ? "Skráning tókst!"
                  : fetcher.state === "submitting"
                    ? "Skráning í gangi.."
                    : "Skrá mig"}
              </Button>
            </fetcher.Form>
          </div>
        </div>
        <div className="flex flex-col md:grid md:grid-rows-2">
          <div className="flex flex-col border-t-1 border-black px-6 py-6 text-xl font-bold md:border-b-1 md:border-l-1 md:border-r-1 md:px-10">
            <div>Opnunartími</div>
            <div className="opacity-50">Virka daga: 10-17</div>
            <div className="opacity-50">Laugardaga: 11-17</div>
          </div>
          <div>
            <div className="flex h-full flex-row items-center justify-between border-b-1 border-t-1 border-black p-6 text-xl font-bold md:flex md:flex-col md:items-start md:justify-start md:border-r-1">
              <Link
                to="https://www.facebook.com/nola.is/"
                className="text-left"
              >
                Facebook
              </Link>
              <Link
                to="https://www.instagram.com/nola.is/"
                className="text-left"
              >
                Instagram
              </Link>
              <Link to="https://www.tiktok.com/@nola_is" className="text-left">
                Tiktok
              </Link>
            </div>
          </div>
          <div className="col-span-2 mb-10 flex flex-col border-b-1 border-black py-6 pl-4 text-base md:border-r-1">
            <FooterLink to="/skilmalar">Skilmálar</FooterLink>
            <FooterLink to="/personuverndarstefna">
              Persónuverndarstefna
            </FooterLink>
            <FooterLink to="/um-okkur">Um okkur</FooterLink>
            <FooterLink to="/tilgangur">Tilgangur</FooterLink>
          </div>
        </div>
      </div>
      <div className="mb-10 hidden items-center justify-center md:visible md:flex md:flex-row">
        <FooterLogoLarge />
      </div>
    </div>
  );
};
