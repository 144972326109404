import { Link, useLoaderData, useNavigate } from "@remix-run/react";
import type {
  CategoryCalculatedProperties,
  Category,
} from "../../../types/Category";
import { CloseIcon } from "../assets/icons/Close";
import { useEffect, useState } from "react";
import { useModal } from "../context/Modal";
import type { Brand } from "../../../types/Brand";
import { Logo } from "~/assets/icons/Logo";
import { ShoppingCart } from "./Header/ShoppingCart";

type TopLevelItem = "Brands" | "Categories" | "SubCategories" | null;

export default function MainMenu() {
  const { categories, brands } = useLoaderData<{
    categories: Array<
      Category &
        CategoryCalculatedProperties & {
          children: Array<Category & CategoryCalculatedProperties>;
        }
    >;
    brands: Array<Brand> | null;
  }>();
  const navigate = useNavigate();
  const { mainMenuVisible, setMainMenuVisible } = useModal();
  const [left, setLeft] = useState("left-[-485px]");
  const [activeTopLevel, setActiveTopLevel] = useState<
    "Brands" | "Categories" | "SubCategories" | null
  >(null);

  useEffect(() => {
    if (mainMenuVisible) {
      setLeft("left-0");
    } else {
      setLeft("left-[-485px]");
    }
  }, [mainMenuVisible]);

  return (
    <div
      className={`${left} fixed top-0 z-30 h-full w-full bg-[#3C5043] px-4 transition-all duration-300 xs:max-w-[485px] xs:px-10`}
    >
      <div className="flex h-full flex-col ">
        <div className="mb-10 flex flex-row items-center justify-between pt-6">
          <div
            className="cursor-pointer"
            onClick={() => {
              setMainMenuVisible(false);
            }}
          >
            <CloseIcon fill="#E3DACC" size="24" />
          </div>
          <div
            className="absolute left-1/2  -translate-x-1/2  transform cursor-pointer"
            onClick={() => navigate("/")}
          >
            <Logo fill="#E3DACC" />
          </div>
          <ShoppingCart fill="#E3DACC" />
        </div>
        <div className="mb-2 mt-4">
          <TopLevelItem
            label="Vörumerki"
            onClick={() => {
              if (activeTopLevel !== "Brands") {
                setActiveTopLevel("Brands");
              } else {
                setActiveTopLevel(null);
              }
            }}
          />
        </div>
        <SubOptions
          options={(brands || [])
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((b) => {
              return { id: b.id, name: b.name, to: `shop?brand=${b.slug}` };
            })}
          parent="Brands"
          active={activeTopLevel}
          onClick={() => {
            setMainMenuVisible(false);
          }}
        />
        <div className="mb-2 mt-4">
          <TopLevelItem
            label="Vöruflokkar"
            onClick={() => {
              if (activeTopLevel !== "Categories") {
                setActiveTopLevel("Categories");
              } else {
                setActiveTopLevel(null);
              }
            }}
          />
        </div>
        <SubOptions
          options={categories.map((c) => {
            return { id: c.id, name: c.name, to: `shop?category=${c.slug}` };
          })}
          parent="Categories"
          active={activeTopLevel}
          onClick={() => {
            setMainMenuVisible(false);
          }}
        />
        <div className="mb-2 mt-4">
          <TopLevelItem
            label="Undirflokkar"
            onClick={() => {
              if (activeTopLevel !== "SubCategories") {
                setActiveTopLevel("SubCategories");
              } else {
                setActiveTopLevel(null);
              }
            }}
          />
        </div>
        <SubOptions
          options={categories
            .map((t) => t.children)
            .map((c) => {
              return c.map((s) => {
                return {
                  id: s.id,
                  name: s.name,
                  to: `shop?subcategory=${s.slug}`,
                };
              });
            })
            .flat(1)
            .sort((a, b) => a.name.localeCompare(b.name))}
          parent="SubCategories"
          active={activeTopLevel}
          onClick={() => {
            setMainMenuVisible(false);
          }}
        />
      </div>
    </div>
  );
}

const TopLevelItem = ({
  label,
  onClick,
}: {
  label: string;
  onClick: () => void;
}) => {
  return (
    <div
      className="cursor-pointer font-FKScreamer text-[48px] font-normal uppercase leading-[40px] text-[#E3DACC]"
      onClick={onClick}
    >
      {label}
    </div>
  );
};

const SubOptions = ({
  options,
  parent,
  active,
  onClick,
}: {
  options: Array<{ id: string; name: string; to: string }>;
  parent: TopLevelItem;
  active: TopLevelItem;
  onClick: () => void;
}) => {
  return (
    <div
      className={`overflow-y-scroll ${
        active === parent ? "h-full" : "h-0"
      } transition-all duration-300`}
    >
      <div className="flex flex-col">
        {options.map((o) => {
          return (
            <Link
              className="cursor-pointer py-1 text-xl font-semibold text-[#E3DACC]"
              key={o.id}
              to={o.to}
              onClick={onClick}
            >
              {o.name}
            </Link>
          );
        })}
      </div>
    </div>
  );
};
