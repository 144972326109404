export const Input = ({
  placeholder,
  name,
  disabled,
}: {
  placeholder: string;
  name: string;
  disabled: boolean;
}) => {
  return (
    <input
      disabled={disabled}
      placeholder={placeholder}
      name={name}
      className={`h-10 border-b-1 border-[#0E0F0F] bg-transparent font-bold text-[#0E0F0F] placeholder-black outline-none placeholder:font-bold ${disabled ? "opacity-50" : "opacity-1"}`}
    />
  );
};
