import { MenuIcon } from "~/assets/icons/Menu";
import { useModal } from "../../context/Modal";

export const Menu = () => {
  const { setMainMenuVisible } = useModal();
  return (
    <div className="items-center md:flex md:flex-row md:gap-16">
      <div className="cursor-pointer " onClick={() => setMainMenuVisible(true)}>
        <div className="hidden text-xl font-bold uppercase md:block">
          Versla
        </div>
        <div className="visible md:hidden">
          <MenuIcon />
        </div>
      </div>
    </div>
  );
};
