import { Logo } from "~/assets/icons/Logo";
import { ShoppingCart } from "~/components/Header/ShoppingCart";
import { Menu } from "./Menu";
import { useNavigate } from "@remix-run/react";
import { SearchSmall } from "~/assets/icons/SearchSmall";
import { useRef } from "react";
import { useModal } from "~/context/Modal";

export const Header = () => {
  const navigate = useNavigate();
  const { searchVisible, setSearchVisible } = useModal();
  const inputRef = useRef<HTMLInputElement>(null);
  return (
    <div className="blurBackground fixed left-0 right-0 top-0 z-20">
      <div className="relative mx-6 flex h-20 flex-row items-center justify-between md:mx-16">
        <div className="flex flex-row items-center gap-2 md:gap-16">
          <Menu />
          <div
            onClick={() => {
              setSearchVisible(!searchVisible);
            }}
            className="flex cursor-pointer flex-row items-center gap-4"
          >
            <SearchSmall />

            <div className="hidden text-xl font-bold uppercase md:block">
              Leita
            </div>
          </div>
        </div>
        <div
          className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform cursor-pointer"
          onClick={() => navigate("/")}
        >
          <Logo />
        </div>
        <div className="flex flex-row items-center gap-16">
          <div className="relative">
            <ShoppingCart />
          </div>
        </div>
      </div>
    </div>
  );
};
