export const LinkArrow = ({ fill }: { fill: string }) => {
  return (
    <svg
      width="17"
      height="15"
      viewBox="0 0 17 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0023 6.971L9.18704 1.15574L9.91309 0.429688L16.9702 7.48678L9.91309 14.5439L9.18704 13.8178L15.0071 7.99779L0.513374 7.99779L0.513374 6.971L15.0023 6.971Z"
        fill={fill}
      />
    </svg>
  );
};
